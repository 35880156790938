import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {type RootState} from '../store';
import {type Form as Application, FormPublicProjection} from '../models/entities/form';
import type {CustomerInput} from '../models/customer-input';
import {CustomerInputErrors} from '../models/customer-input-errors';
import {CustomerInputService} from '../services/customer-input-service';
import {isFileUploadElementItem} from '../models/elements/form/input/file-upload-element';
import {FormState} from '../models/dtos/form-state';
import {AnyElement} from '../models/elements/any-element';
import {collectReferences, Reference} from '../utils/build-references';
import {ElementWithParents, flattenElements, flattenElementsWithParents} from '../utils/flatten-elements';


const initialState: {
    // Future states of the loaded form. These get created when changes are undone
    futureLoadedForm: Array<Application | FormPublicProjection>,
    // The form that has been loaded
    loadedForm?: Application | FormPublicProjection;
    // Past states of the loaded form. These get created when changes are done
    pastLoadedForm: Array<Application | FormPublicProjection>,
    // Reference tree for the loaded form
    functionReferences?: Reference[];
    // A list of all elements in the form
    allElements?: AnyElement[];
    // A list of all elements in the form with their parents
    allElementsWithParents?: ElementWithParents[];

    // ID of the dialog to show
    showDialog?: string;

    // Whether the customer input has been loaded from the local storage
    hasLoadedSavedCustomerInput: boolean;
    // Inputs the customer has actively entered
    inputs: CustomerInput;
    // Inputs that have been changed in the last update cycle
    changedInputs: string[];
    // Values the customer has entered or were computes
    values: CustomerInput;
    // Errors for the customer input
    errors: CustomerInputErrors;
    // Record of disabled elements
    disabled: Record<string, boolean>;
    // Record of invisible elements
    visibilities: Record<string, boolean>;
    // Record of element overrides
    overrides: Record<string, AnyElement>;
} = {
    futureLoadedForm: [],
    pastLoadedForm: [],
    hasLoadedSavedCustomerInput: false,
    inputs: {},
    changedInputs: [],
    values: {},
    errors: {},
    disabled: {},
    visibilities: {},
    overrides: {},
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        clearLoadedForm: (state, _: PayloadAction<void>) => {
            state.loadedForm = undefined;
            state.functionReferences = undefined;
            state.allElements = undefined;
            state.allElementsWithParents = undefined;
            state.futureLoadedForm = [];
            state.pastLoadedForm = [];
            state.showDialog = undefined;
            state.hasLoadedSavedCustomerInput = false;
            state.inputs = {};
            state.changedInputs = [];
            state.values = {};
            state.errors = {};
            state.disabled = {};
            state.visibilities = {};
            state.overrides = {};
        },

        updateLoadedForm: (state, action: PayloadAction<Application | FormPublicProjection>) => {
            if (state.loadedForm != null) {
                state.pastLoadedForm.push(state.loadedForm);
                state.futureLoadedForm = [];
            }
            state.loadedForm = action.payload;
            state.functionReferences = collectReferences(state.loadedForm.root);
            state.allElements = flattenElements(state.loadedForm.root);
            state.allElementsWithParents = flattenElementsWithParents(state.loadedForm.root, [], false);
        },

        undoLoadedForm: (state, _: PayloadAction<void>) => {
            if (state.pastLoadedForm.length > 0 && state.loadedForm != null) {
                state.futureLoadedForm.push(state.loadedForm);
                state.loadedForm = state.pastLoadedForm.pop();
                if (state.loadedForm != null) {
                    state.functionReferences = collectReferences(state.loadedForm.root);
                    state.allElements = flattenElements(state.loadedForm.root);
                    state.allElementsWithParents = flattenElementsWithParents(state.loadedForm.root, [], false);
                } else {
                    state.functionReferences = undefined;
                    state.allElements = undefined;
                    state.allElementsWithParents = undefined;
                }
            }
        },

        redoLoadedForm: (state, _: PayloadAction<void>) => {
            if (state.futureLoadedForm.length > 0 && state.loadedForm != null) {
                state.pastLoadedForm.push(state.loadedForm);
                state.loadedForm = state.futureLoadedForm.pop();
                if (state.loadedForm != null) {
                    state.functionReferences = collectReferences(state.loadedForm.root);
                    state.allElements = flattenElements(state.loadedForm.root);
                    state.allElementsWithParents = flattenElementsWithParents(state.loadedForm.root, [], false);
                } else {
                    state.functionReferences = undefined;
                    state.allElements = undefined;
                    state.allElementsWithParents = undefined;
                }
            }
        },

        clearLoadedFormHistory: (state, _: PayloadAction<void>) => {
            state.pastLoadedForm = [];
            state.futureLoadedForm = [];
        },

        showDialog: (state, action: PayloadAction<string | undefined>) => {
            state.showDialog = action.payload;
        },

        setHasLoadedSavedCustomerInput: (state, action: PayloadAction<boolean>) => {
            state.hasLoadedSavedCustomerInput = action.payload;
        },

        hydrateCustomerInput: (state, action: PayloadAction<CustomerInput>) => {
            const cleanedSaveData = {
                ...action.payload,
            };
            for (const key of Object.keys(cleanedSaveData)) {
                const val = cleanedSaveData[key];
                if (Array.isArray(val) && val.length > 0 && isFileUploadElementItem(val[0])) {
                    delete cleanedSaveData[key];
                }
            }
            state.inputs = cleanedSaveData;
            if (state.loadedForm != null) {
                CustomerInputService.storeCustomerInput(state.loadedForm, cleanedSaveData);
            }
        },

        clearCustomerInput: (state, _: PayloadAction<void>) => {
            state.inputs = {};
            state.changedInputs = [];
            state.values = {};
            state.disabled = {};
            state.errors = {};
            state.visibilities = {};
            state.overrides = {};
            if (state.loadedForm != null) {
                CustomerInputService.cleanCustomerInput(state.loadedForm);
            }
        },

        updateCustomerInput: (state, action: PayloadAction<{ key: string, value: any }>) => {
            const lastInputs = {
                ...state.inputs,
            };
            const newInput = {
                ...state.inputs,
                [action.payload.key]: action.payload.value,
            };
            state.inputs = newInput;
            state.values = {
                ...state.values,
                [action.payload.key]: undefined,
            }

            state.changedInputs = Object.keys(newInput).filter(key => newInput[key] !== lastInputs[key]);

            if (state.loadedForm != null) {
                CustomerInputService.storeCustomerInput(state.loadedForm, newInput);
            }
        },

        addError: (state, action: PayloadAction<{ key: string, error: string; }>) => {
            state.errors[action.payload.key] = action.payload.error;
        },
        clearErrors: (state, _: PayloadAction<void>) => {
            state.errors = {};
        },

        hydrateDisabled: (state, action: PayloadAction<Record<string, boolean>>) => {
            state.disabled = action.payload;
        },
        clearDisabled: (state, _: PayloadAction<void>) => {
            state.disabled = {};
        },

        hydrateFromDerivation: (state, action: PayloadAction<FormState>) => {
            state.values = action.payload.values;
            state.visibilities = action.payload.visibilities;
            state.errors = action.payload.errors;
            state.overrides = action.payload.overrides;
        },
        hydrateFromDerivationWithoutErrors: (state, action: PayloadAction<FormState>) => {
            state.values = action.payload.values;
            state.visibilities = action.payload.visibilities;
            state.overrides = action.payload.overrides;
        },
    },
});

export const {
    clearLoadedForm,
    updateLoadedForm,
    undoLoadedForm,
    redoLoadedForm,
    clearLoadedFormHistory,
    showDialog,
    setHasLoadedSavedCustomerInput,
    hydrateCustomerInput,
    clearCustomerInput,
    updateCustomerInput,
    addError,
    clearErrors,
    hydrateDisabled,
    clearDisabled,
    hydrateFromDerivation,
    hydrateFromDerivationWithoutErrors,
} = appSlice.actions;

export const selectLoadedForm = (state: RootState) => state.app.loadedForm;
export const selectPastLoadedForm = (state: RootState) => state.app.pastLoadedForm;
export const selectFutureLoadedForm = (state: RootState) => state.app.futureLoadedForm;
export const selectCustomerInputValue = (key: string) => (state: RootState) => state.app.inputs[key];
export const selectCustomerInputError = (key: string) => (state: RootState) => state.app.errors[key];
export const selectHasLoadedSavedCustomerInput = () => (state: RootState) => state.app.hasLoadedSavedCustomerInput;
export const selectFunctionReferences = (state: RootState) => state.app.functionReferences;
export const selectAllElements = (state: RootState) => state.app.allElements;
export const selectAllElementsWithParents = (state: RootState) => state.app.allElementsWithParents;

export const selectDisabled = (key: string) => (state: RootState) => state.app.disabled[key];
export const selectVisibility = (key: string) => (state: RootState) => state.app.visibilities[key] ?? true;
export const selectError = (key: string) => (state: RootState) => state.app.errors[key];
export const selectOverride = (key: string) => (state: RootState) => state.app.overrides[key];
export const selectValue = (key: string) => (state: RootState) => state.app.inputs[key] ?? state.app.values[key];
export const selectChangedInputs = (state: RootState) => state.app.changedInputs;

export const appReducer = appSlice.reducer;
