import {RootElement} from '../models/elements/root-element';
import {FunctionType, hasElementFunctionType} from './function-status-utils';
import {isAnyInputElement} from '../models/elements/form/input/any-input-element';
import {JavascriptCode} from '../models/functions/javascript-code';
import {isNoCodeExpression, isNoCodeReference, NoCodeExpression, ValidationExpressionWrapper} from '../models/functions/no-code-expression';
import {ConditionSet} from '../models/functions/conditions/condition-set';
import {Function} from '../models/functions/function';
import {flattenElements, flattenElementsWithParents} from './flatten-elements';
import {StepElement} from '../models/elements/steps/step-element';
import {AnyElement} from '../models/elements/any-element';
import {ElementType} from '../data/element-type/element-type';

export interface Reference {
    source: AnyElement;
    target: AnyElement;
    sourceStep: StepElement;
    targetStep: StepElement;
    functionType: FunctionType;
    isSameStep: boolean;
    sourceIsStep: boolean;
}

export function collectReferences(root: RootElement): Reference[] {
    const allElements = flattenElementsWithParents(root, [], false);
    const references: Reference[] = [];

    const addReference = (sourceElement: AnyElement, parents: AnyElement[], targetId: string, type: FunctionType) => {
        const target = allElements.find((element) => element.element.id === targetId);
        if (target == null) {
            return;
        }
        const targetElement = target.element;
        const targetParents = target.parents;

        let sourceStep = parents.find((element) => element.type === ElementType.Step) as StepElement | undefined;
        let targetStep = targetParents.find((element) => element.type === ElementType.Step) as StepElement | undefined;

        if (sourceStep == null && sourceElement.type === ElementType.Step) {
            sourceStep = sourceElement as StepElement;
        }

        if (targetStep == null && targetElement.type === ElementType.Step) {
            targetStep = targetElement as StepElement;
        }

        if (sourceStep == null || targetStep == null) {
            console.warn('Could not find step for reference', sourceElement, targetElement);
            return;
        }

        references.push({
            source: sourceElement,
            target: targetElement,
            sourceStep: sourceStep,
            targetStep: targetStep,
            functionType: type,
            isSameStep: sourceStep === targetStep,
            sourceIsStep: sourceElement.type === ElementType.Step,
        });
    };

    for (const {element, parents} of allElements) {
        if (hasElementFunctionType(element, FunctionType.VISIBILITY)) {
            const referencedIds = resolveReferencesByFunctions(element.isVisible, element.visibilityCode, element.visibilityExpression, undefined);
            for (const id of referencedIds) {
                addReference(element, parents, id, FunctionType.VISIBILITY);
            }
        }

        if (hasElementFunctionType(element, FunctionType.OVERRIDE)) {
            const referencedIds = resolveReferencesByFunctions(element.patchElement, element.overrideCode, element.overrideExpression, undefined);
            for (const id of referencedIds) {
                addReference(element, parents, id, FunctionType.OVERRIDE);
            }
        }

        if (isAnyInputElement(element)) {
            if (hasElementFunctionType(element, FunctionType.VALUE)) {
                const referencedIds = resolveReferencesByFunctions(element.computeValue, element.valueCode, element.valueExpression, undefined);
                for (const id of referencedIds) {
                    addReference(element, parents, id, FunctionType.VALUE);
                }
            }

            if (hasElementFunctionType(element, FunctionType.VALIDATION)) {
                const referencedIds = resolveReferencesByFunctions(element.validate, element.validationCode, undefined, element.validationExpressions);
                for (const id of referencedIds) {
                    addReference(element, parents, id, FunctionType.VALIDATION);
                }
            }
        }
    }

    const uniqueReferences: Reference[] = [];
    for (const reference of references) {
        if (!uniqueReferences.some((r) => r.source === reference.source && r.target === reference.target && r.functionType === reference.functionType)) {
            uniqueReferences.push(reference);
        }
    }

    return uniqueReferences
        .sort((a, b) => a.source.id.localeCompare(b.source.id));
}


export interface RootReferenceTree {
    [stepId: string]: {
        step: StepElement;
        references: StepReferenceTree;
    };
}

interface StepReferenceTree {
    // Element ID -> Referenced by these element IDs for the given function type
    [elementId: string]: {
        element: AnyElement;
        referencedBy: {
            [type in FunctionType]?: AnyElement[];
        }
    };
}

interface ReferenceMap {
    [idOfTheReferencedElement: string]: {
        referencedElement: AnyElement;
        stepTheReferencedElementIsIn: StepElement;
        referencesToThisElement: {
            [idOfTheReferencingElement: string]: {
                referencingElement: AnyElement;
                stepTheReferencingElementIsIn: StepElement;
                functionTypeTheReferenceIsIn: FunctionType;
            }[];
        }
    };
}

interface ReferenceSource {
    referencingElement: AnyElement;
    stepTheReferencingElementIsIn: StepElement;
    functionTypeTheReferenceIsIn: FunctionType;
}


export function _collectReferences(root: RootElement): null {
    const rootReferenceTree: RootReferenceTree = {};


    for (const step of root.children) {
        const allElementsInStep = [
            ...root.children,
            ...flattenElements(step),
        ];
        const allElementIdsInStep = allElementsInStep.map((element) => element.id);

        const stepReferenceTree: StepReferenceTree = {};
        rootReferenceTree[step.id] = {
            step: step,
            references: stepReferenceTree,
        };

        const addReference = (sourceElement: AnyElement, referencesElementId: string, forFunctionType: FunctionType) => {
            let references = stepReferenceTree[referencesElementId];
            if (references == null) {
                references = {
                    element: allElementsInStep.find((element) => element.id === referencesElementId) as AnyElement,
                    referencedBy: {},
                };
                stepReferenceTree[referencesElementId] = references;
            }

            let referencesForFunctionType = references.referencedBy[forFunctionType];
            if (referencesForFunctionType == null) {
                referencesForFunctionType = [];
                references.referencedBy[forFunctionType] = referencesForFunctionType;
            }

            referencesForFunctionType.push(sourceElement);
        };

        for (const child of allElementsInStep) {
            if (hasElementFunctionType(child, FunctionType.VISIBILITY)) {
                const referencedIds = resolveReferencesByFunctions(child.isVisible, child.visibilityCode, child.visibilityExpression, undefined);
                for (const id of referencedIds) {
                    if (allElementIdsInStep.includes(id)) {
                        addReference(child, id, FunctionType.VISIBILITY);
                    }
                }
            }

            if (hasElementFunctionType(child, FunctionType.OVERRIDE)) {
                const referencedIds = resolveReferencesByFunctions(child.patchElement, child.overrideCode, child.overrideExpression, undefined);
                for (const id of referencedIds) {
                    if (allElementIdsInStep.includes(id)) {
                        addReference(child, id, FunctionType.OVERRIDE);
                    }
                }
            }

            if (isAnyInputElement(child)) {
                if (hasElementFunctionType(child, FunctionType.VALUE)) {
                    const referencedIds = resolveReferencesByFunctions(child.computeValue, child.valueCode, child.valueExpression, undefined);
                    for (const id of referencedIds) {
                        if (allElementIdsInStep.includes(id)) {
                            addReference(child, id, FunctionType.VALUE);
                        }
                    }
                }

                if (hasElementFunctionType(child, FunctionType.VALIDATION)) {
                    const referencedIds = resolveReferencesByFunctions(child.validate, child.validationCode, undefined, child.validationExpressions);
                    for (const id of referencedIds) {
                        if (allElementIdsInStep.includes(id)) {
                            addReference(child, id, FunctionType.VALIDATION);
                        }
                    }
                }
            }
        }
    }

    return null;
}

function resolveReferencesByFunctions(func: Function | undefined | null, js: JavascriptCode | undefined | null, expression: NoCodeExpression | undefined | null, valWrapper: ValidationExpressionWrapper[] | undefined | null): string[] {
    const idsThisElementReferences: string[] = [];

    if (func != null) {
        if (func.code != null) {
            const occurrences = func.code.match(/>>>([a-zA-Z0-9_-]+)/g);
            if (occurrences != null) {
                for (const occurrence of occurrences) {
                    const id = occurrence.substring(3);
                    idsThisElementReferences.push(id);
                }
            }
        }

        if (func.conditionSet != null) {
            idsThisElementReferences.push(...resolveConditionSet(func.conditionSet));
        }
    }

    if (js != null && js.code != null) {
        const occurrences = js.code.match(/>>>([a-zA-Z0-9_-]+)/g);
        if (occurrences != null) {
            for (const occurrence of occurrences) {
                const id = occurrence.substring(3);
                idsThisElementReferences.push(id);
            }
        }
    }

    if (expression != null) {
        idsThisElementReferences.push(...resolveExpression(expression));
    }

    if (valWrapper != null) {
        for (const val of valWrapper) {
            if (val.expression != null) {
                idsThisElementReferences.push(...resolveExpression(val.expression));
            }
        }
    }

    return idsThisElementReferences;
}

function resolveConditionSet(conditionSet: ConditionSet): string[] {
    const references: string[] = [];

    if (conditionSet.conditions != null) {
        for (const condition of conditionSet.conditions) {
            if (condition.target != null) {
                references.push(condition.target);
            }
            if (condition.reference != null) {
                references.push(condition.reference);
            }
        }
    }

    if (conditionSet.conditionsSets != null) {
        for (const childConditionSet of conditionSet.conditionsSets) {
            references.push(...resolveConditionSet(childConditionSet));
        }
    }

    return references;
}

function resolveExpression(expression: NoCodeExpression): string[] {
    const references: string[] = [];

    if (expression.operands != null) {
        for (const operand of expression.operands) {
            if (isNoCodeReference(operand)) {
                references.push(operand.elementId);
            }
            if (isNoCodeExpression(operand)) {
                references.push(...resolveExpression(operand));
            }
        }
    }

    return references;
}